<app-header></app-header>
<app-search-large></app-search-large>
<!-- <main id="main"> -->
<section id="about" class="about bg-white">
    <div class="container">
        <div class="section-title">
            <h2>DentIndia</h2>
            <h3>The Dental <span>Land mark</span></h3>
            <p>Let the DentIndia direct you toward to top places.</p>
        </div>
        <div class="row content">
            <div class="col-lg-12">
                <p>
                    DentIndia provides the complete and secure software solution for Dentist and Dental Clinic. It helps in maintaining all the patient records digitally and can be accessed anywhere. For dentist this will a complete package where not only managing clinical
                    records but also provides new updates in dentistry. Our vision is to provide the best dental platform in India.
                </p>
                <ul class="text-list listx">
                    <li><i class="icofont-swoosh-right"></i> Patient Registration</li>
                    <li><i class="icofont-swoosh-right"></i> Appointment management</li>
                    <li><i class="icofont-swoosh-right"></i> Reminder SMS</li>
                    <li><i class="icofont-swoosh-right"></i> Patient accounts</li>
                    <li><i class="icofont-swoosh-right"></i> Search your Patient using multiple search option</li>
                    <li><i class="icofont-swoosh-right"></i> Prescription print</li>
                    <li><i class="icofont-swoosh-right"></i> Customisable prescriptions</li>
                    <li><i class="icofont-swoosh-right"></i> Lab management</li>
                    <li><i class="icofont-swoosh-right"></i> Email and SMS Reminder</li>
                    <li><i class="icofont-swoosh-right"></i> Email and SMS Festival wishes</li>
                    <li><i class="icofont-swoosh-right"></i> Reports</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section id="services" class="services ghost-white">
    <div class="container">
        <div class="section-title">
            <h2>Services</h2>
            <h3>How It <span>Works?</span></h3>
            <p>Establishing an account with Doctor and Clinic is easy now with dentindia and start feeling better today</p>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="work-process step-1">
                    <span class="process-icon bg-danger-light">
                            <i class="icofont-users-alt-5"></i>
                            <span class="process-count bg-danger cl-white">1</span>
                    </span>
                    <h4>Create an account</h4>
                    <p>Create an account with basic information of doctor and clinic.</p>
                </div>
            </div>

            <div class="col-md-4 col-sm-4">
                <div class="work-process step-2">
                    <span class="process-icon bg-success-light">
                            <i class="icofont-options"></i>
                            <span class="process-count bg-success cl-white">2</span>
                    </span>
                    <h4>Choose application</h4>
                    <p>We are providing online clinic database, dental classifieds etc,</p>
                </div>
            </div>

            <div class="col-md-4 col-sm-4">
                <div class="work-process step-3">
                    <span class="process-icon bg-purple-light">
                            <i class="icofont-thumbs-up"></i>
                            <span class="process-count bg-purple cl-white">3</span>
                    </span>
                    <h4>Start using</h4>
                    <p>Enjoy with your digital journey!</p>
                </div>
            </div>

        </div>
    </div>
</section>
<app-price-table></app-price-table>
<!-- </main> -->
<app-footer></app-footer>