<section id="hero">
    <div class="hero-container">
        <h1>The Dental Land mark</h1>
        <h2>DentIndia helps you find all top Dentists. Our vision is to provide the best dental platform in India</h2>
        <form class="dentindia-form form" [formGroup]="searchForm" (ngSubmit)="onSubmit()" novalidate="novalidate">
            <div class="container">
                <div class="main-search-form col-sm-10 auto">
                    <div class="row">
                        <div class="col-md-5 col-sm-5 grp">
                            <div class="form-group search">
                                <label for="what" class="col-form-label">What?</label>
                                <input type="text" name="search_text" formControlName="search_text" class="form-control"
                                    placeholder="What are you looking for?" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': submitted && f.search_text.errors }">
                                <div *ngIf="submitted && f.search_text.errors" class="invalid-feedback">
                                    <div *ngIf="f.search_text.errors.required">Search text is required</div>
                                    <div *ngIf="f.search_text.errors.minlength">Text must be at least 3 characters</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 grp">
                            <div class="form-group location">
                                <label for="input-location" class="col-form-label">Where?</label>
                                <ng-select2 [data]="locationData" formControlName="location" [placeholder]="'select...'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 grp">
                            <div class="form-group cat">
                                <label for="category-selectized" class="col-form-label">Category?</label>
                                <ng-select2 [data]="sourceData" formControlName="category" [placeholder]="'select...'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 grp">
                            <button type="submit" name="search" class="btn btn-dent">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>