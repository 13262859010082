<section id="pricing" class="pricing bg-white">
    <div class="container">
        <div class="section-title">
            <h2>Ppricing</h2>
            <h3>Providing a great services with the <span>best price</span></h3>
            <p>Let the DentIndia direct you towards to top places</p>
        </div>
        <div id="download-template">
            <div class="row gx-1 download-template">
                <div class="col-xl-4 d-none d-xl-block features-list">
                    <ul>
                        <li> <span>Patient Registration</span> </li>
                        <li> <span>Registration/Scheduling/Remainder SMS </span> </li>
                        <li> <span>Reports (Patient & category) </span> </li>
                        <li> <span>Prescriptions & Bills</span> </li>
                        <li> <span>Custom/Festival SMS</span></li>
                        <li> <span>Classifieds</span></li>
                    </ul>
                </div>
                <div class="col-xl-8 licenses">
                    <div class="row gx-1">
                        <div class="col-lg-4 mt-4 mt-lg-0 free">
                            <div class="title">
                                <h2>Free</h2> <span>₹0/mo</span>
                            </div>
                            <!-- <div class="price">
                                <h3> <sup>₹</sup> 0</h3> <span> per month</span>
                            </div> -->
                            <div class="features">
                                <ul>
                                    <li><i class="icon icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Patient Registration</span></li>
                                    <li><i class="icofont-close-line"></i><span class="d-block d-xl-none feature-title">Registration/Scheduling/Remainder SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Reports (Patient & category)</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Prescriptions & Bills</span></li>
                                    <li><i class="icofont-close-line"></i><span class="d-block d-xl-none feature-title">Custom/Festival SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Classifieds</span></li>
                                </ul>
                            </div>
                            <div class="action"> <a href="" rel="nofollow" class="buy"><span>Choose Plan</span></a></div>
                        </div>
                        <div class="col-lg-4 mt-5 mt-lg-0 pro">
                            <div class="title">
                                <h2>Pro</h2> <span>₹600/mo</span>
                            </div>
                            <!--  <div class="price">
                                <div class="d-flex justify-content-center align-items-center">
                                    <h3> <sup>₹</sup> 600</h3>
                                </div> <span>per month</span>
                            </div> -->
                            <div class="features">
                                <ul>
                                    <li><i class="icon icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Patient Registration</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Registration/Scheduling/Remainder SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Reports (Patient & category)</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Prescriptions & Bills</span></li>
                                    <li><i class="icofont-close-line"></i><span class="d-block d-xl-none feature-title">Custom/Festival SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Classifieds</span></li>
                                </ul>
                            </div>
                            <div class="action"> <a href="" rel="nofollow" class="buy"><span>Choose Plan</span></a></div>
                        </div>
                        <div class="col-lg-4 mt-5 mt-lg-0 membership">
                            <div class="title">
                                <h2>Membership</h2> <span>₹1200/mo</span>
                            </div>
                            <!--  <div class="price">
                                <div class="d-flex justify-content-center align-items-center">
                                    <h3> <sup>₹</sup> 1200</h3>
                                </div> <span>per month</span>
                            </div> -->
                            <div class="features">
                                <ul>
                                    <li><i class="icon icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Patient Registration</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Registration/Scheduling/Remainder SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Reports (Patient & category)</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Prescriptions & Bills</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Custom/Festival SMS</span></li>
                                    <li><i class="icofont-check-alt"></i><span class="d-block d-xl-none feature-title">Classifieds</span></li>
                                </ul>
                            </div>
                            <div class="action"> <a href="" rel="nofollow" class="buy"><span>Choose Plan</span></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>