<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <div class="card login-card">
            <div class="row no-gutters">
                <div class="col-md-6 col-sm-10 col-xs-12 auto">
                    <div class="card-body login">
                        <p class="login-card-description">Fogot password?</p>
                        <form action="#!" class="login-form">
                            <div class="form-group">
                                <label for="email" class="">Email</label>
                                <input type="text" name="email" id="email" class="form-control" placeholder="Email">
                                <p class="small">Ex: Jone@company.com</p>
                            </div>
                            <input name="password_request" id="password_request" class="btn btn-primary mt-3 mb-4" type="button" value="Continue">
                        </form>
                        <p class="login-card-footer-text">Already having dentindia account? <a href="account/login" class="text-reset">Login here</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>