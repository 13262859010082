<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <div class="card login-card no-shadow">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="card-body register login">
                        <p class="login-card-description">Register clinic account</p>
                        <form method="#" action="#" class="clinic_register_form">
                            <div class="row">
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Country</label>
                                    <input type="text" class="form-control" value="India" disabled>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">State <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <ng-select2 [data]="stateData" [placeholder]="'select...'"></ng-select2>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">District <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <ng-select2 [data]="districtData" [placeholder]="'select...'"></ng-select2>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label block">&nbsp;</label>
                                    <input name="login" id="check" class="btn btn-outline-info btn-block" data-toggle="modal" data-target="#getDoctorModal" type="button" value="Get Doctor">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Clinic Name <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="clinic_name" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Logo <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="file" accept="image/x-png,image/gif,image/jpeg" class="file" name="file" autocomplete="off">
                                    <div class="input-group col-xs-12">
                                        <input type="text" class="form-control" readonly="" autocomplete="off">
                                        <span class="input-group-btn">
                                          <button class="browse btn btn-primary" type="button"><i class="icofont-search"></i></button>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Doctor Name <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="doctor_name" disabled required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Dcotor DCI # <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="dci_no" disabled required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Email <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="email" class="form-control" name="email" pattern="(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Mobile Number<sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="mobile_no" mask="9999999999" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Address <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <textarea class="form-control" name="address" required></textarea>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Zipcode <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="zipcode" mask="999999" required>
                                </div>
                                <div class="col-sm-12">
                                    <hr>
                                </div>
                                <!-- groupx -->
                                <div class="form-group col-sm-12 text-center pt-2 pb-2">
                                    <a href="register" class="float-left back_a"> <i class="icofont-double-left"></i>Back</a>
                                    <input type="submit" name="register_clinic" id="check" class="btn login-btn" type="button" value="Register Clinic Profile">
                                    <a href="login" class="float-right back_a"> Goto login <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
<div class="modal fade select2Modal" tabindex="-1" id="getDoctorModal" role="dialog" aria-labelledby="getDoctorModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Search Doctor</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modelx">
                <form method="post" data-toggle="validator" enctype="multipart/form-data" class="html5Form clinic_add_form form-horizontal" data-bv-message="This value is not valid" data-bv-feedbackicons-valid="glyphicon glyphicon-ok" data-bv-feedbackicons-invalid="glyphicon glyphicon-remove"
                    data-bv-feedbackicons-validating="glyphicon glyphicon-refresh" id="docotr_add_modal_form" name="clinic_add_form">
                    <div class="row">
                        <div class="form-group col-sm-3">
                            <label class="control-label">State <sup class="important"><i class="fa fa-asterisk" aria-hidden="true"></i></sup></label>
                            <ng-select2 [data]="stateData" [placeholder]="'select...'"></ng-select2>
                        </div>
                        <div class="form-group col-sm-6">
                            <label class="control-label">Search by Doctor Name / DCI number <sup class="important"><i class="fa fa-asterisk" aria-hidden="true"></i></sup></label>
                            <input type="text" class="form-control" id="search_text" name="search_text" value="" required="" />
                        </div>
                        <div class="form-group col-sm-3">
                            <label class="control-label">&nbsp;</label>
                            <button type="button" onclick="getDoctorSearchModalData('<?=BASE_URL;?>');" class="btn btn-block login-btn">Search</button>
                        </div>
                    </div>
                    <div class="alert alert-warning pull-left d-none" role="alert">
                        Please select state and enter docotor name/DCI Number to get the result
                    </div>
                    <div class="clearfix">
                        <hr>
                    </div>
                </form>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs">
                    </ag-grid-angular>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button> -->
                <button type="button" class="btn btn-info" data-dismiss="modal">Select Doctor</button>
            </div>
        </div>
    </div>
</div>