import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { LoginUser } from '../../model/login-user.model';
import { data } from 'jquery';
import { AccountService, AlertService } from '..';
import { Patient } from '../../dashboard-clinic/model/patient.model';

@Injectable({ providedIn: 'root' })
export class PatientService {
    user!: LoginUser;
    public patient!: Observable<Patient>;

    public isNewPatientSubject = new Subject<any>();
    public patientUpdate = new Subject<any>();
    newPatient$ = this.isNewPatientSubject.asObservable();
    patientUpdate$ = this.patientUpdate.asObservable();

    constructor(private router: Router, private accountService: AccountService, private http: HttpClient, private alertService: AlertService) {
        this.accountService.user.subscribe(x => this.user = x);
    }

    newPatient(value: any) {
        this.isNewPatientSubject.next(value);
    }

    isPatientUpdate(value: any) {
        this.patientUpdate.next(value);
    }

    public totalCount(tabel: string): Observable<any> {
        const data: any = {
            table: tabel,
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get(`${environment.patientUrl}/total-count`, { params: data }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    public getNewPatientId(): Observable<any> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get<Patient>(`${environment.patientUrl}/newPatientId`, { params: data }).pipe(
            map(res => {
                return (res) ? Number(res?.patient_id) + 1 : 100001;
            }),
            catchError(error => this.handleError(error)));
    }

    public savePatient(formData: any): Observable<any> {
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        formData.registration_date = this.formatDate(formData.patient_registration_date);
        formData.dob = this.formatDate(formData.patient_dob);
        return this.http.post(`${environment.patientUrl}/create`, formData).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    public updatePatient(formData: any): Observable<any> {
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        formData.registration_date = this.formatDate(formData.patient_registration_date);
        formData.dob = this.formatDate(formData.patient_dob);
        return this.http.post(`${environment.patientUrl}/update`, formData).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getSearchPatient(formData: any): Observable<any> {
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        return this.http.get<Patient>(`${environment.patientUrl}/search`, { params: formData }).pipe(
            map(patient => {
                return patient;
            }),
            catchError(error => this.handleError(error)));
    }

    getPatient(pid: any): Observable<any> {
        let data: any = [];
        data.pid = pid;
        data.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        return this.http.get<Patient>(`${environment.patientUrl}/get`, { params: data }).pipe(
            map(patient => {
                return patient;
            }),
            catchError(error => this.handleError(error)));
    }

    updatePatientClinicNotes(value: any): Observable<any> {
        value.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        value.patient_profile_id = this.getPatientInfo()?.patient_profile_id | 0;
        value.cheif_complaints = value.cheif_complaints && Array.isArray(value.cheif_complaints) ? value.cheif_complaints.join(',') : value.cheif_complaints;
        value.medical_history = value.medical_history && Array.isArray(value.medical_history) ? value.medical_history.join(',') : value.medical_history;
        value.allergic = value.allergic && Array.isArray(value.allergic) ? value.allergic.join(',') : value.allergic;
        value.medication = value.medication && Array.isArray(value.medication) ? value.medication.join(',') : value.medication;
        return this.http.post(`${environment.patientUrl}/updateClicNotes`, value).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    addTreatmentDone(value: any): Observable<any> {
        value.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        value.treatment_date = this.formatDate(value.treatment_date);
        value.patient_profile_id = this.getPatientInfo()?.patient_profile_id | 0;
        return this.http.post(`${environment.patientUrl}/addTreatment`, value).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    updateTreatmentDone(value: any): Observable<any> {
        value.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        value.treatment_date = this.formatDate(value.treatment_date);
        value.patient_profile_id = this.getPatientInfo()?.patient_profile_id | 0;
        return this.http.post(`${environment.patientUrl}/updateTreatment`, value).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    updateTreatmentNotes(value: any) {
        const data = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id,
            patient_profile_id: this.getPatientInfo()?.patient_profile_id | 0,
            treatment_notes: value
        }
        let info = this.getPatientInfo();

        info ? info.treatment_notes = value : info.treatment_notes = null;

        localStorage.setItem('patient', JSON.stringify(info));
        return this.http.post(`${environment.patientUrl}/updateTreatmentNotes`, data).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    updatePatientCategory(value: any) {
        const data = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id,
            patient_profile_id: this.getPatientInfo()?.patient_profile_id | 0,
            patient_category: value
        }
        let info = this.getPatientInfo();

        info ? info.patient_category = value : info.patient_category = null;

        localStorage.setItem('patient', JSON.stringify(info));
        return this.http.post(`${environment.patientUrl}/updatePatientCategory`, data).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getClinicalNotes(): Observable<any> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get(`${environment.patientUrl}/clinicalNotes`, { params: data }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getTreatment(): Observable<any> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get(`${environment.patientUrl}/treatment`, { params: data }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getPatientCategoryList(): Observable<any> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get(`${environment.patientUrl}/category`, { params: data }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getTreatmentDoneListAndDoctorData(): Observable<any[]> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        const treatmentData: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id,
            patient_profile_id: this.getPatientInfo()?.patient_profile_id | 0
        };
        const treatmentReq = this.http.get(`${environment.apiUrl}/treatment`, { params: data });
        const doctorReq = this.http.get(`${environment.apiUrl}/doctors`, { params: data });
        const treatmentDoneListReq = this.http.get(`${environment.patientUrl}/treatmentDone`, { params: treatmentData });

        return forkJoin([treatmentReq, doctorReq, treatmentDoneListReq]).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getTreatmentAndDoctorData(): Observable<any[]> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        const treatmentData: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id,
            patient_profile_id: this.getPatientInfo()?.patient_profile_id | 0
        };
        const treatmentReq = this.http.get(`${environment.apiUrl}/treatment`, { params: data });
        const doctorReq = this.http.get(`${environment.apiUrl}/doctors`, { params: data });

        return forkJoin([treatmentReq, doctorReq]).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }
    getDoctors(): Observable<any[]> {
        const data: any = {
            clinic_doctor_login_id: this.user.clinic_doctor_login_id
        };
        return this.http.get(`${environment.apiUrl}/doctors`, { params: data }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    formatDate(date: any): any {
        if (date !== "" && date !== null) {
            if (date?.year) {
                return date.year + '-' + date.month + '-' + date.day;
            } else {
                const dateSplit = date.split('-');
                return dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
            }
        }
        return null;
    }

    handleError(error: HttpErrorResponse) {
        let that = this;
        that.alertService.clear();
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        } else {
            errorMessage = `Error Code: ${error.status}\n Message: ${error.message}`;
        }
        that.alertService.error(errorMessage);
        return throwError(errorMessage);
    }

    setHeader() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json; charset=utf-8')
            .set('Access-Control-Allow-Origin', '*');
        return headers;
    }

    public getPatientInfo() {
        let patient: any = localStorage.getItem('patient');
        if (patient !== null)
            return JSON.parse(patient);
        else return patient;
    }

    public datePicker(value: any) {
        let dateVal: any = new Date(value);
        if (value === 'today') {
            dateVal = new Date();
        } else if (value === "0000-00-00") {
            dateVal = null;
        }
        if (dateVal !== null) {
            dateVal = {
                year: parseInt(dateVal.getFullYear()),
                month: parseInt(dateVal.getMonth() + 1),
                day: parseInt(dateVal.getDate()),
            };
        }
        return dateVal;

    }
    public datePickerMoment(value: any) {
        let dateVal: any = new Date(value);
        if (value === 'today') {
            dateVal = new Date();
        } else if (value === "0000-00-00") {
            dateVal = null;
        }
        if (dateVal !== null) {
            dateVal = {
                year: parseInt(dateVal.getFullYear()),
                month: parseInt(dateVal.getMonth()),
                day: parseInt(dateVal.getDate()),
            };
        }
        return dateVal;

    }

    public saveAppointment(formData: any): Observable<any> {
        const amOrPmValue = this.amorPM(formData.appt_time);
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        formData.appt_registration_date = this.formatDate(formData.appt_registration_date);
        formData.appt_time = amOrPmValue;
        formData.action = 'new';
        return this.http.post(`${environment.patientUrl}/appointment`, formData).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getSearchAppointment(formData: any): Observable<any> {
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        formData.action = 'search';
        return this.http.get(`${environment.patientUrl}/appointment`, { params: formData }).pipe(
            map(data => {
                return data;
            }),
            catchError(error => this.handleError(error)));
    }

    getAppointmentsByPatientId(pid): Observable<any> {
        const formData = {
            'clinic_doctor_login_id': this.user.clinic_doctor_login_id.toString(),
            'pid': pid,
            'action': 'appointmentsByPatientId'
        }
        return this.http.get(`${environment.patientUrl}/appointment`, { params: formData }).pipe(
            map(data => {
                return data;
            }),
            catchError(error => this.handleError(error)));
    }

    deleteAppointment(appointment_id): Observable<any> {
        const formData = {
            'action': 'delete',
            'appointment_id': appointment_id
        }
        return this.http.post(`${environment.patientUrl}/appointment`, formData).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getBalanceAccount(): Observable<any> {
        const formData = {
            'clinic_doctor_login_id': this.user.clinic_doctor_login_id.toString()
        }
        
        return this.http.get(`${environment.patientUrl}/patientAccounts`, { params: formData }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    getOrthoCaseSheet(): Observable<any> {
        const formData = {
            'clinic_doctor_login_id': this.user.clinic_doctor_login_id.toString(),
            'action' : 'orthoData'
        }
        
        return this.http.get(`${environment.patientUrl}/orthoCaseSheet`, { params: formData }).pipe(
            map((res: any) => res),
            catchError(error => this.handleError(error)));
    }

    saveOrthoCaseSheet(formData: any, type): Observable<any> {
        const patient = this.getPatientInfo();
        formData.clinic_doctor_login_id = this.user.clinic_doctor_login_id;
        formData.firstDate = this.formatDate(formData.firstDate);
        formData.action = type;
        formData.patient_id = patient?.patient_id;
        formData.patient_profile_id = patient?.patient_profile_id;
        return this.http.post(`${environment.patientUrl}/orthoCaseSheet`, formData).pipe(
            map(data => {
                return data;
            }),
            catchError(error => this.handleError(error)));
    }


    amorPM(value) {
        let amOrPm = "AM";
        if (value?.substring(0, 2) < 12) {
            amOrPm = "AM";
        } else {
            amOrPm = "PM";
        }
        return (value?.substring(0, 2) % 12 || 12).toString().padStart(2, '0') + value.slice(2) + ' ' + amOrPm;
    }

}