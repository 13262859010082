<header id="header" class="fixed-top">
    <button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
    <div class="mobile-nav-overly d-lg-none"></div>
    <div class="container d-flex justify-content-between">
        <h1 class="logo mr-auto"><a href=""> Dentindia</a></h1>
        <!-- <a href="index.html" class="logo mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        <nav class="nav-menu d-none d-lg-block">
            <ul>
                <li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>
                <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
                <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
                <li class="login_link"><a routerLink="/account/login"><i class="icofont-lock"></i> Log In</a> </li>
            </ul>
        </nav>
        <nav class="mobile-nav d-lg-none">
            <ul>
                <li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>
                <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
                <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
                <li class="login_link"><a routerLink="/account/login"><i class="icofont-lock"></i> Log In</a> </li>
            </ul>
        </nav>
        <!-- .nav-menu -->
    </div>
</header>