import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'account/register', component: RegisterComponent },
  { path: 'account/login', component: LoginComponent },
  { path: 'account/forgot-password', component: ForgotPasswordComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'dashboard', loadChildren: () => import("./dashboard-clinic/dashboard.module").then(m => m.DashboardModule)
    // component: DashboardHomeComponent, canActivate: [AuthGuard],
    // children: [
    //   { path: '', component: LayoutComponent, canActivate: [AuthGuard] },
    //   { path: 'patient', pathMatch: 'full', component: ExistingPatientComponent, canActivate: [AuthGuard]},
    //   { path: 'patient/new', pathMatch: 'full', component: NewPatientComponent, canActivate: [AuthGuard] },
    //   { path: 'patient/bill', pathMatch: 'full', component: BillComponent, canActivate: [AuthGuard] },
    //   { path: 'patient/category', pathMatch: 'full', component: PatientCategoryComponent, canActivate: [AuthGuard] },
    //   { path: 'patient/ortho-case-sheet', pathMatch: 'full', component: OrthoCaseSheetComponent, canActivate: [AuthGuard] },
    //   { path: 'appointment', component: AppointmentListComponent, canActivate: [AuthGuard] },
    //   { path: 'appointment/new', component: NewAppointmentComponent, canActivate: [AuthGuard] },
    //   { path: 'account', component: DueComponent, canActivate: [AuthGuard] },
    //   { path: 'prescription', component: PrescriptionsComponent, canActivate: [AuthGuard] },
    //   { path: 'sms', component: SmsComponent, canActivate: [AuthGuard] },
    //   { path: 'sms/send', component: SmsSendComponent, canActivate: [AuthGuard] },
    //   { path: 'sms/sender-id', component: SmsSenderComponent, canActivate: [AuthGuard] },
    //   { path: 'sms/template', component: SmsTemplateComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/manage-doctors', component: ManageDoctorComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/medicine-types', component: MedicineTypesComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/medicine', component: MedicineSettingComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/treatment', component: TreatmentSettingComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/patient-category', component: ConfigPatientCategoryComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/cheif-complaint', component: CheifComplaintComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/medical-history', component: MedicalHistoryComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/allergic-info', component: AlergicInfoComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/medication', component: MedicationComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/prescription', component: PrescriptionSettingComponent, canActivate: [AuthGuard] },
    //   { path: 'configuration/printer', component: PrintComponent, canActivate: [AuthGuard] },
    //   { path: 'report/revenue', component: RevenueReportComponent, canActivate: [AuthGuard] },
    //   { path: 'report/patient', component: PatientReportComponent, canActivate: [AuthGuard] },
    //   { path: 'report/appointment', component: AppointmentReportComponent, canActivate: [AuthGuard] },
    //   { path: 'report/doctor', component: DoctorReportComponent, canActivate: [AuthGuard] },
    //   { path: 'report/sms', component: SmsReportComponent, canActivate: [AuthGuard] },
    //   { path: 'report/subscription', component: SubscriptionReportComponent, canActivate: [AuthGuard] },
    //   { path: 'subscription/active', component: ActivePlanComponent, canActivate: [AuthGuard] },
    //   { path: 'subscription/summary', component: SummaryComponent, canActivate: [AuthGuard] },
    //   { path: 'subscription/plan', component: AllPlanComponent, canActivate: [AuthGuard] },
    //   { path: 'admin/clinic-profile', component: ClinicProfileComponent, canActivate: [AuthGuard] },
    //   { path: 'admin/doctor-profile', component: DoctorProfileComponent, canActivate: [AuthGuard] },
    //   { path: 'admin/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] }
    // ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', 
    enableTracing: false,
    relativeLinkResolution: "corrected",
    useHash: false,
    paramsInheritanceStrategy: "always"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
