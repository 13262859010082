<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <section class="main about">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">
                        <h3>The Dental <span>Land mark</span></h3>
                        <p>Let the DentIndia direct you toward to top places.</p>
                    </div>
                    <div class="content">
                        <p>
                            DentIndia provides the complete and secure software solution for Dentist and Dental Clinic. It helps in maintaining all the patient records digitally and can be accessed anywhere. For dentist this will a complete package where not only managing clinical
                            records but also provides new updates in dentistry. Our vision is to provide the best dental platform in India.
                        </p>
                        <ul class="text-list listx">
                            <li><i class="icofont-swoosh-right"></i> Patient Registration</li>
                            <li><i class="icofont-swoosh-right"></i> Appointment management</li>
                            <li><i class="icofont-swoosh-right"></i> Reminder SMS</li>
                            <li><i class="icofont-swoosh-right"></i> Patient accounts</li>
                            <li><i class="icofont-swoosh-right"></i> Search your Patient using multiple search option</li>
                            <li><i class="icofont-swoosh-right"></i> Prescription print</li>
                            <li><i class="icofont-swoosh-right"></i> Customisable prescriptions</li>
                            <li><i class="icofont-swoosh-right"></i> Lab management</li>
                            <li><i class="icofont-swoosh-right"></i> Email and SMS Reminder</li>
                            <li><i class="icofont-swoosh-right"></i> Email and SMS Festival wishes</li>
                            <li><i class="icofont-swoosh-right"></i> Reports</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>
<app-footer></app-footer>