import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor() { }

  login_success = 'Logged in successfully!!';
  login_fail = 'Error! Login failed. Please recheck the username/password and try again.';
  noPermission = "You don't have permission to access inside patient account.";
  patient_save = 'Patient profile saved successfully!';
  patient_update = 'Patient profile updated successfully!';
  clinic_notes_update = 'Clinical notes updated successfully!';
  treatment_done_add = 'Treatment detail added successfully!';
  treatment_done_update = 'Treatment detail updated successfully!';
  treatment_note_update = 'Treatment notes saved successfully!';
  mandatory_fill = 'Please fill out all required fields.';
  appintment_save = 'Appoitnment created successfully!';
  appintment_error = 'Appoitnment not created. please try again.';
  appt_delete_success = 'Appointment deleted successfully';
  error = 'OOPS! something wrong please try again.';
  update = 'updated successfully!';

  save = '{{title}} created successfully!';

  category = [
    { id: 'doctor', text: 'Doctor' },
    { id: 'clinic', text: 'Clinic' }
  ];
  gender = [
    { id: 'Male', text: 'Male' },
    { id: 'Female', text: 'Female' },
    { id: 'Third Gender', text: 'Third Gender' },
    { id: 'Other', text: 'Other' }
  ];
  religion = [
    { id: 'Hindu', text: 'Hindu' },
    { id: 'Muslim', text: 'Muslim' },
    { id: 'Christian', text: 'Christian' },
    { id: 'Jain', text: 'Jain' },
    { id: 'Other', text: 'Other' }
  ];
  pix = [
    { id: 'Mr.', text: 'Mr.' },
    { id: 'Mrs.', text: 'Mrs.' },
    { id: 'Ms.', text: 'Ms.' },
    { id: 'Miss', text: 'Miss' },
    { id: 'Master', text: 'Master' },
    { id: '', text: 'Other' }
  ];
  patient_filter = [
    { id: 'patient_id', text: 'Patient ID' },
    { id: 'name', text: 'Name' },
    { id: 'mobile', text: 'Mobile' },
    { id: 'email', text: 'Email' },
    { id: 'year', text: 'Year of Registration' }
  ];

  
  appt_filter = [
    { id: 'patient_profile_id', text: 'Patient Profile ID' },
    { id: 'name', text: 'Name' },
    { id: 'mobile', text: 'Mobile' },
    { id: 'email', text: 'Email' },
    { id: 'year', text: 'Year of Registration' }
  ];

  blood_group = [
    { id: 'A+', text: 'A+' },
    { id: 'B+', text: 'B+' },
    { id: 'AB+', text: 'AB+' },
    { id: 'O+', text: 'O+' },
    { id: 'A-', text: 'A-' },
    { id: 'B-', text: 'B-' },
    { id: 'AB-', text: 'AB-' },
    { id: 'O-', text: 'O-' }
  ];
  duration = [
    { id: '15', text: '15 min' },
    { id: '30', text: '30 min' },
    { id: '60', text: '1 hr' },
    { id: '180', text: '3 hrs' }];
}
