// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// C:\wamp64\www\Rohini\DENTIN\dentindia\api

export const environment = {
  production: false,
  baseUrl: 'https://dentindia.webrexlab.com/',
  apiUrl: 'https://api-dentindia.webrexlab.com/api',
  loginUrl: 'https://api-dentindia.webrexlab.com/api/login',
  registerUrl: 'https://api-dentindia.webrexlab.com/api/register',
  patientUrl: 'https://api-dentindia.webrexlab.com/api/patient',
  stateUrl: 'https://api-dentindia.webrexlab.com/api/state',
  clinicUrl: 'https://api-dentindia.webrexlab.com/api/clinic',
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
