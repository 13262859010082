import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { NgSelect2Module } from 'ng-select2';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { RegisterClinicComponent } from './account/register-clinic/register-clinic.component';
import { RegisterDoctorComponent } from './account/register-doctor/register-doctor.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HeaderComponent } from './default/header/header.component';
import { FooterComponent } from './default/footer/footer.component';
import { SearchLargeComponent } from './default/search-large/search-large.component';
import { SearchSmallComponent } from './default/search-small/search-small.component';
import { PriceTableComponent } from './default/price-table/price-table.component';
import { FaqComponent } from './pages/faq/faq.component'; 
import { HomeComponent } from './pages/home/home.component';
// import { NavComponent } from './dashboard-clinic/common/nav/nav.component';
// import { NewPatientComponent } from './dashboard-clinic/patient/new-patient/new-patient.component';
// import { ExistingPatientComponent } from './dashboard-clinic/patient/existing-patient/existing-patient.component';
// import { BasicInfoComponent } from './dashboard-clinic/patient/basic-info/basic-info.component';
// import { ClinicalNotesComponent } from './dashboard-clinic/patient/clinical-notes/clinical-notes.component';
// import { TreatmentNotesComponent } from './dashboard-clinic/patient/treatment-notes/treatment-notes.component';
// import { NewAppointmentComponent } from './dashboard-clinic/appointment/new-appointment/new-appointment.component';
// import { AppointmentListComponent } from './dashboard-clinic/appointment/appointment-list/appointment-list.component';
// import { PatientCategoryComponent } from './dashboard-clinic/patient/patient-category/patient-category.component';
// import { ManageDoctorComponent } from './dashboard-clinic/configuration/manage-doctor/manage-doctor.component';
// import { MedicineTypesComponent } from './dashboard-clinic/configuration/medicine-types/medicine-types.component';
// import { MedicineSettingComponent } from './dashboard-clinic/configuration/medicine-setting/medicine-setting.component';
// import { PrescriptionSettingComponent } from './dashboard-clinic/configuration/prescription-setting/prescription-setting.component';
// import { TreatmentSettingComponent } from './dashboard-clinic/configuration/treatment-setting/treatment-setting.component';
// import { CheifComplaintComponent } from './dashboard-clinic/configuration/cheif-complaint/cheif-complaint.component';
// import { MedicalHistoryComponent } from './dashboard-clinic/configuration/medical-history/medical-history.component';
// import { AlergicInfoComponent } from './dashboard-clinic/configuration/alergic-info/alergic-info.component';
// import { MedicationComponent } from './dashboard-clinic/configuration/medication/medication.component';
// import { DueComponent } from './dashboard-clinic/accounts/due/due.component';
// import { PrescriptionsComponent } from './dashboard-clinic/patient/prescriptions/prescriptions.component';
// import { SmsComponent } from './dashboard-clinic/sms/sms/sms.component';
// import { SmsSendComponent } from './dashboard-clinic/sms/sms-send/sms-send.component';
// import { SmsSenderComponent } from './dashboard-clinic/sms/sms-sender/sms-sender.component';
// import { SmsTemplateComponent } from './dashboard-clinic/sms/sms-template/sms-template.component';
// import { DashboardHomeComponent } from './dashboard-clinic/home/home.component';
// import { DashboardHeaderComponent } from './dashboard-clinic/common/header/header.component';
// import { DashboardFooterComponent } from './dashboard-clinic/common/footer/footer.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { AlertComponent } from './default/alert/alert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './default/spinner/spinner.component';
import { ScrollbarModule } from './default/scrollbar/scrollbar.module';
import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';
// import { LayoutComponent } from './dashboard-clinic/layout/layout.component';
// import { PrintComponent } from './dashboard-clinic/configuration/print/print.component';
// import { PatientReportComponent } from './dashboard-clinic/report/patient-report/patient-report.component';
// import { AppointmentReportComponent } from './dashboard-clinic/report/appointment-report/appointment-report.component';
// import { DoctorReportComponent } from './dashboard-clinic/report/doctor-report/doctor-report.component';
// import { SmsReportComponent } from './dashboard-clinic/report/sms-report/sms-report.component';
// import { SubscriptionReportComponent } from './dashboard-clinic/report/subscription-report/subscription-report.component';
// import { RevenueReportComponent } from './dashboard-clinic/report/revenue-report/revenue-report.component';
// import { ActivePlanComponent } from './dashboard-clinic/subscription/active-plan/active-plan.component';
// import { SummaryComponent } from './dashboard-clinic/subscription/summary/summary.component';
// import { AllPlanComponent } from './dashboard-clinic/subscription/all-plan/all-plan.component';
// import { ClinicProfileComponent } from './dashboard-clinic/admin/clinic-profile/clinic-profile.component';
// import { DoctorProfileComponent } from './dashboard-clinic/admin/doctor-profile/doctor-profile.component';
// import { ChangePasswordComponent } from './dashboard-clinic/admin/change-password/change-password.component';
import { DataTablesModule } from 'angular-datatables';
// import { BreadcrumbPipe } from './dashboard-clinic/pipe/breadcrumb.pipe';
import { AuthInterceptor } from './helper/authInterceptor';
import { CommonModule } from '@angular/common';
import { NgbDateCustomParserFormatter } from './service/ngb-date-fr-parser-formatter';
// import { OrthoCaseSheetComponent } from './dashboard-clinic/patient/ortho-case-sheet/ortho-case-sheet.component';
// import { BillComponent } from './dashboard-clinic/patient/bill/bill.component';
// import { FirstCheckListComponent } from './dashboard-clinic/patient/first-check-list/first-check-list.component';
// import { DayCheckListComponent } from './dashboard-clinic/patient/day-check-list/day-check-list.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterClinicComponent,
    RegisterDoctorComponent,
    AboutUsComponent,
    ServicesComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    SearchLargeComponent,
    SearchSmallComponent,
    PriceTableComponent,
    FaqComponent,
    HomeComponent,
    // NavComponent,
    // NewPatientComponent,
    // ExistingPatientComponent,
    // BasicInfoComponent,
    // ClinicalNotesComponent,
    // TreatmentNotesComponent,
    // NewAppointmentComponent,
    // AppointmentListComponent,
    // PatientCategoryComponent,
    // ManageDoctorComponent,
    // MedicineTypesComponent,
    // MedicineSettingComponent,
    // PrescriptionSettingComponent,
    // TreatmentSettingComponent,
    // CheifComplaintComponent,
    // MedicalHistoryComponent,
    // AlergicInfoComponent,
    // MedicationComponent,
    // DueComponent,
    // PrescriptionsComponent,
    // SmsComponent,
    // SmsSendComponent,
    // SmsSenderComponent,
    // SmsTemplateComponent,
    // DashboardHomeComponent,
    // DashboardHeaderComponent,
    // DashboardFooterComponent,
    ForgotPasswordComponent,
    AlertComponent,
    SpinnerComponent,
    // LayoutComponent,
    // PrintComponent,
    // PatientReportComponent,
    // AppointmentReportComponent,
    // DoctorReportComponent,
    // SmsReportComponent,
    // SubscriptionReportComponent,
    // RevenueReportComponent,
    // ActivePlanComponent,
    // SummaryComponent,
    // AllPlanComponent,
    // ClinicProfileComponent,
    // DoctorProfileComponent,
    // ChangePasswordComponent,
    // BreadcrumbPipe,
    // OrthoCaseSheetComponent,
    // BillComponent,
    // FirstCheckListComponent,
    // DayCheckListComponent
  ],
  imports: [
    BrowserModule,
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgSelect2Module,
    NgSelectModule,
    HttpClientModule,
    ScrollbarModule,
    NgbModule,
    DataTablesModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(maskConfig)
  ],
  providers: [NG_EVENT_PLUGINS, {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
  bootstrap: [AppComponent]
})
export class AppModule { }
