import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class UtilService {
    filterDropdownData(data: any[]) {
        let resArr: { id: any; text: any; }[] = [];
        data.forEach(function (item: { id: any; text: any; }) {
            var i = resArr.findIndex(x => x.text == item.text);
            if (i <= -1) {
                resArr.push({ id: item.id, text: item.text });
            }
        });
        return resArr;
    }
}