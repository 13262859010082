import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.spinner.stop();
  }
  
  ngOnDestroy() {
    this.spinner.start();
  }

}
