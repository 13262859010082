import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountService } from '../service/account.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let loggedInUser = this.accountService.loggedInUserValue;
        let user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user?.token;
        if (token) {
            ("token updated");
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

        }
        // return next.handle(request);
        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.accountService.logout();
                    }
                }
            }));
    }

}