<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <div class="card login-card no-shadow">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="card-body register login">
                        <p class="login-card-description">Register doctor account</p>
                        <form method="#" action="#" class="clinic_register_form">
                            <div class="row">
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Country</label>
                                    <input type="text" class="form-control" value="India" disabled>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">State <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <ng-select2 [data]="stateData" [placeholder]="'select...'"></ng-select2>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">District <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <ng-select2 [data]="districtData" [placeholder]="'select...'"></ng-select2>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Doctor Name <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="clinic_name" required>
                                </div>

                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Dcotor DCI # <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="dci_no" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Logo <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="file" accept="image/x-png,image/gif,image/jpeg" class="file" name="file" autocomplete="off">
                                    <div class="input-group col-xs-12">
                                        <input type="text" class="form-control" readonly="" autocomplete="off">
                                        <span class="input-group-btn">
                                          <button class="browse btn btn-primary" type="button"><i class="icofont-search"></i></button>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Date of Birth</label>
                                    <input type="text" class="form-control  form_datetime" name="dob" value="" placeholder="" data-date-format="dd/mm/yyyy" mask="99/99/9999" />
                                </div>

                                <div class="form-group col-sm-3">
                                    <label class="control-label">Qualification <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <select name="qualification" class="form-control select2" data-bv-notempty="true" data-bv-message="Please select qualification">
                                        <option value="">--</option>
                                        <? echo getQualificationDropdown();?>
                                      </select>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Email <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="email" class="form-control" name="email" pattern="(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Mobile Number<sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="mobile_no" mask="9999999999" required>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Address <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <textarea class="form-control" name="address" required></textarea>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="col-form-label">Zipcode <sup class="important"><i
                                                class="icofont-star"></i></sup></label>
                                    <input type="text" class="form-control" name="zipcode" mask="999999" required>
                                </div>
                                <div class="col-sm-12">
                                    <hr>
                                </div>
                                <!-- groupx -->
                                <div class="form-group col-sm-12 text-center pt-2 pb-2">
                                    <a href="register" class="float-left back_a"> <i class="icofont-double-left"></i>Back</a>
                                    <input type="submit" name="register_doctor" id="check" class="btn login-btn" type="button" value="Register Doctor Profile">

                                    <a href="login" class="float-right back_a"> Goto login <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>