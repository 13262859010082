<footer id="footer">
    <div class="footer-top ghost-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 footer-contact">
                    <div class="">
                        <h3><i class="icofont-location-pin"></i></h3>
                        <p>
                            No - 8, PR Sundaram Iyer Street, Dharmapuri - 636701, Tamilnadu, India
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 footer-contact">
                    <div class="">
                        <h3><i class="icofont-phone"></i></h3>
                        <p>
                            <span class="details">
                                <a href="callto://09942962605">R. Gopinath :- (+91) 99429 62605
                                </a>  <br>
                                <a href="callto://09884433947">Dr. Bhasker&nbsp; :- (+91) 98844 33947</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 footer-contact">
                    <div class="">
                        <h3><i class="far fa-envelope"></i></h3>
                        <p>
                            <span class="details">
                                <a href="mailto://info@dentindia.com">info@dentindia.com</a><br>
                                 <a href="http://www.dentindia.com" target="_blank">www.dentindia.com</a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container d-md-flex py-2 justify-content-between">
        <div class="mr-md-auto text-center text-md-left copy-center">
            <div class="copyright">
                &copy; Copyright <span>Dentindia</span>. All Rights Reserved
            </div>
            <div class="credits">
            </div>
        </div>
        <div class="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
            <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
            <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
            <a href="#" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
        </div>
    </div>
</footer>
<!-- End Footer -->
<!-- <a href="javaScript:void(0)" class="back-to-top"><i class="icofont-thin-up"></i></a> -->