import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.spinner.stop();
  }

  ngOnDestroy() {
    this.spinner.start();
  }
}
