import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AlertService, SpinnerService } from './service';
import { PatientService } from './service/dashboard-service/patient.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'clinic';
  constructor(
    private router: Router, 
    private spinner: SpinnerService, 
    private patientService: PatientService,
    private alertService: AlertService) { }

  ngOnInit() {

    setTimeout(() => {
      this.spinner.stop();
        $('#header').removeClass('mobile-nav-active');
        $('.mobile-nav-toggle i').removeClass('icofont-close');
        $('.mobile-nav-toggle i').addClass('icofont-navigation-menu');
        $('.mobile-nav-overly').hide();
    }, 500);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  
    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');
    // this.loadPatient()
  }
  ngOnDestroy() {
    this.spinner.start();
  }

  // loadPatient(){
  //   this.spinner.start();
  //   let patientInfo = this.patientService.getPatientInfo();
  //   if (patientInfo?.patient_id) {
  //     this.patientService.getPatient(patientInfo?.patient_id).pipe(first()).subscribe(
  //       (response) => {
  //         localStorage.setItem('patient', JSON.stringify(response));
  //         // this.alertService.success('Patient data refereshed successfully.');
  //         this.spinner.stop();
  //       },
  //       (error) => {
  //         localStorage.setItem('patient', 'null');
  //         this.patientService.newPatient(true);
  //         // this.alertService.warn('Error occured on refereshing patient data. please try again');
  //         this.spinner.stop();
  //       });
  //   }
  //   else {
  //     // this.alertService.warn('Error occured on refereshing patient data. please try again');
  //     this.spinner.stop();
  //   }
  // }

}
