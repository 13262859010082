import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-doctor',
  templateUrl: './register-doctor.component.html',
  styleUrls: ['./register-doctor.component.css']
})
export class RegisterDoctorComponent implements OnInit {
  
  public stateData:any;
  public districtData:any;
  constructor() { }

  ngOnInit(): void {
  }

}
