import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService, ConstantService, SpinnerService } from '../../service';

@Component({ templateUrl: './login.component.html' })
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService, private spinner: SpinnerService, public constant: ConstantService
  ) { }

  ngOnInit() {
    this.spinner.stop();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.accountService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          (data)
          if (data.success === true) {
            this.alertService.success(this.constant.login_success);
            localStorage.setItem('patient', 'null');
            const returnUrl = 'dashboard';
            // this.router.navigateByUrl(returnUrl);
            this.router.navigate([returnUrl]);
          } else {
            this.alertService.error(this.constant.login_fail);
            this.loading = false;
          }
        },
        error: error => {
          console.log("error:", error)
          this.alertService.error(this.constant.error);
          this.loading = false;
        }
      });
  }
  ngOnDestroy() {
    this.spinner.start();
  }
}