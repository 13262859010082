import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService, ConstantService, SpinnerService } from '../../service';
import * as _ from 'lodash';
import { UtilService } from 'src/app/service/util.service';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  public clinicStateData: any;
  public clinicDistrictData: any;
  public doctorStateData: any;
  public doctorDistrictData: any;
  public state: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private spinner: SpinnerService,
    private util: UtilService, private constant: ConstantService
  ) { }

  ngOnInit() {
    setTimeout(() => this.spinner.stop(), 5000);

    this.setStateData();

    this.form = this.formBuilder.group({
      clinicName: ['', [Validators.required, Validators.minLength(3)]],
      clinicEmail: ['', [Validators.required, Validators.email]],
      clinicMobile: ['', [Validators.required, Validators.minLength(10)]],
      clinicAddress: ['', [Validators.required, Validators.minLength(3)]],
      clinicState: ['', Validators.required],
      clinicDistrict: ['', Validators.required],
      clinicZip: ['', [Validators.required, Validators.minLength(6)]],
      clinicGstInNo: [''],
      doctorName: ['', [Validators.required, Validators.minLength(3)]],
      doctorDob: ['', Validators.minLength(10)],
      doctorEmail: ['', [Validators.required, Validators.email]],
      doctorMobile: ['', [Validators.required, Validators.minLength(10)]],
      doctorAddress: ['', [Validators.required, Validators.minLength(3)]],
      doctorState: ['', Validators.required],
      doctorDistrict: ['', Validators.required],
      doctorZip: ['', [Validators.required, Validators.minLength(6)]],
      doctorDciNo: ['', [Validators.required, Validators.minLength(3)]],
      doctorQualification: ['', Validators.required],
      acceptTerms: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  setStateData() {
    this.accountService
      .retriveState()
      .pipe(first())
      .subscribe({
        next: (result) => {
          const state = []
          for (let [key, value] of Object.entries(result)) {
            const arr = { id: value.state_id, text: value.name };
            state.push(arr);
          }
          this.state = result;
          this.clinicStateData = this.util.filterDropdownData(state);
          this.doctorStateData = this.util.filterDropdownData(state);
        },
        error: (error) => {
          this.alertService.error(this.constant.error);
          this.loading = false;
        },
      });
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.spinner.start();
    this.loading = true;
    this.accountService
      .register(this.form.value)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          if (result?.status === 'error') {
            this.loading = false;
            this.alertService.warn(result?.msg);
            this.spinner.stop();
          } else {
            this.alertService.success('Clinic profile successfully created!!', { keepAfterRouteChange: true });
            setTimeout(() => {
              this.spinner.stop();
              this.router.navigate(['../login'], { relativeTo: this.route });
            }, 1000);
          }
        },
        error: (error) => {
          this.alertService.error('OOPS! Your request could not be processed. Please contact the system administrator or try again later.');
          this.spinner.stop();
          this.loading = false;
        },
      });
  }

  stateChange(event: any) {
    if (event) {
      const clinicDistrictData = this.state.filter((item: any) => item.code === this.state.find((obj: any) => obj.state_id == event)?.code);
      const district: any[] = [];
      clinicDistrictData.map((item: any) => {
        const arr = { id: item.state_id, text: item.city };
        district.push(arr);
      })
      this.clinicDistrictData = this.util.filterDropdownData(district)
    }
  }

  doctorStateChange(event: any) {
    if (event) {
      const doctorDistrictData = this.state.filter((item: any) => item.code === this.state.find((obj: any) => obj.state_id == event)?.code);
      const district: any[] = [];
      doctorDistrictData.map((item: any) => {
        const arr = { id: item.state_id, text: item.city };
        district.push(arr);
      })
      this.doctorDistrictData = this.util.filterDropdownData(district)
    }
  }
}
