<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <div class="card login-card">
            <div class="row no-gutters">
                <div class="col-md-12 auto">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form row no-gutters">
                        <h3 class="login-card-description text-center">Create Clinic Profile</h3>
                        <div class="login col-sm-10 row auto">
                            <div class="login col-sm-12 mb-3">
                                <p class="card-title">Clinic Info</p>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">Clinic Name
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="clinicName" id="clinicName" formControlName="clinicName"
                                    class="form-control" placeholder="Clinic Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicName.errors }">
                                <div *ngIf="submitted && f.clinicName.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicName.errors.required">Clinic Name is required</div>
                                    <div *ngIf="f.clinicName.errors.minlength">Clinic Name must be min of 3 char(s)</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">GSTIN #</label>
                                <input type="text" name="clinicGstInNo" id="clinicGstInNo"
                                    formControlName="clinicGstInNo" class="form-control" placeholder="000000">
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="email" class="">Email
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="email" name="clinicEmail" id="clinicEmail" class="form-control"
                                    placeholder="dental@company.com" formControlName="clinicEmail"
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicEmail.errors }">
                                <div *ngIf="submitted && f.clinicEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicEmail.errors.required">Email Id is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="mobile" class="">Mobile
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="clinicMobile" id="clinicMobile" mask="9999999999"
                                    class="form-control" placeholder="9999999999" formControlName="clinicMobile"
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicMobile.errors }">
                                <div *ngIf="submitted && f.clinicMobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicMobile.errors.required">Mobile is required</div>
                                    <div *ngIf="f.clinicMobile.errors.minlength">Mobile must be 10 digits</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">State
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <!-- <input type="text" name="clinicState" id="clinicState" formControlName="clinicState"
                                    class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicState.errors }"> -->
                                <ng-select2 [data]="clinicStateData" [placeholder]="'Select...'" name="clinicState"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clinicState.errors }"
                                    formControlName="clinicState" (ngModelChange)="stateChange($event)"></ng-select2>
                                <div *ngIf="submitted && f.clinicState.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicState.errors.required">State is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">District
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <!-- <input type="text" name="clinicDistrict" id="clinicDistrict"
                                    formControlName="clinicDistrict" class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicDistrict.errors }"> -->
                                <ng-select2 [data]="clinicDistrictData" [placeholder]="'Select...'" name="clinicDistrict"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicDistrict.errors }"
                                    formControlName="clinicDistrict"></ng-select2>
                                <div *ngIf="submitted && f.clinicDistrict.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicDistrict.errors.required">District is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">Zip Code
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="clinicZip" mask="999999" id="clinicZip"
                                    formControlName="clinicZip" class="form-control" placeholder="999999"
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicZip.errors }">
                                <div *ngIf="submitted && f.clinicZip.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicZip.errors.required">Zip code is required</div>
                                    <div *ngIf="f.clinicZip.errors.minlength">Zip cide must be 6 digits</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-8">
                                <label for="name" class="">Address
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="clinicAddress" id="clinicAddress"
                                    formControlName="clinicAddress" class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.clinicAddress.errors }">
                                <div *ngIf="submitted && f.clinicAddress.errors" class="invalid-feedback">
                                    <div *ngIf="f.clinicAddress.errors.required">Address is required</div>
                                    <div *ngIf="f.clinicAddress.errors.minlength">Address must be min of 3 char(s)</div>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-12 mb-3"></div>
                        <div class="login col-sm-10 row auto">
                            <div class="login col-sm-12 mb-3">
                                <p class="card-title">Doctor Info</p>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">Doctor Name
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorName" id="doctorName" formControlName="doctorName"
                                    class="form-control" placeholder="Doctor Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorName.errors }">
                                <div *ngIf="submitted && f.doctorName.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorName.errors.required">Doctor Name is required</div>
                                    <div *ngIf="f.doctorName.errors.minlength">Doctor Name must be min of 3 char(s)</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">DCI No
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorDciNo" id="doctorDciNo" formControlName="doctorDciNo"
                                    class="form-control" placeholder="Doctor Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorDciNo.errors }">
                                <div *ngIf="submitted && f.doctorDciNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorDciNo.errors.required">DCI No is required</div>
                                    <div *ngIf="f.doctorDciNo.errors.minlength">DCI No must be min of 3 char(s)</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">Qualification
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorQualification" id="doctorQualification"
                                    formControlName="doctorQualification" class="form-control" placeholder="Doctor Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorQualification.errors }">
                                <div *ngIf="submitted && f.doctorQualification.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorQualification.errors.required">Qualification is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="email" class="">Email
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="email" name="doctorEmail" id="doctorEmail" class="form-control"
                                    placeholder="dental@company.com" formControlName="doctorEmail"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorEmail.errors }">
                                <div *ngIf="submitted && f.doctorEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorEmail.errors.required">Email Id is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="mobile" class="">Mobile
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorMobile" id="doctorMobile" mask="9999999999"
                                    class="form-control" placeholder="9999999999" formControlName="doctorMobile"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorMobile.errors }">
                                <div *ngIf="submitted && f.doctorMobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorMobile.errors.required">Mobile is required</div>
                                    <div *ngIf="f.doctorMobile.errors.minlength">Mobile must be 10 digits</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="name" class="">State
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <!-- <input type="text" name="doctorState" id="doctorState" formControlName="doctorState"
                                    class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorState.errors }"> -->
                                <ng-select2 [data]="doctorStateData" [placeholder]="'Select...'" name="doctorState"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.doctorState.errors }"
                                    formControlName="doctorState" (ngModelChange)="doctorStateChange($event)"></ng-select2>
                                <div *ngIf="submitted && f.doctorState.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorState.errors.required">State is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-3">
                                <label for="name" class="">District
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <!-- <input type="text" name="doctorDistrict" id="doctorDistrict"
                                    formControlName="doctorDistrict" class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorDistrict.errors }"> -->
                                <ng-select2 [data]="doctorDistrictData" [placeholder]="'Select...'" name="doctorDistrict"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorDistrict.errors }"
                                    formControlName="doctorDistrict"></ng-select2>
                                <div *ngIf="submitted && f.doctorDistrict.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorDistrict.errors.required">District is required</div>
                                </div>
                            </div>
                            
                            <div class="form-group col-sm-2">
                                <label for="name" class="">Zip Code
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorZip" mask="999999" id="doctorZip"
                                    formControlName="doctorZip" class="form-control" placeholder="999999"
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorZip.errors }">
                                <div *ngIf="submitted && f.doctorZip.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorZip.errors.required">Zip code is required</div>
                                    <div *ngIf="f.doctorZip.errors.minlength">Zip cide must be 6 digits</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-7">
                                <label for="name" class="">Address
                                    <sup class="important"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></sup></label>
                                <input type="text" name="doctorAddress" id="doctorAddress"
                                    formControlName="doctorAddress" class="form-control" placeholder=""
                                    [ngClass]="{ 'is-invalid': submitted && f.doctorAddress.errors }">
                                <div *ngIf="submitted && f.doctorAddress.errors" class="invalid-feedback">
                                    <div *ngIf="f.doctorAddress.errors.required">Address is required</div>
                                    <div *ngIf="f.doctorAddress.errors.minlength">Address must be min of 3 char(s)</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mb-5"></div>
                        <div class="login col-sm-10 auto text-center">
                            <div class="custom-control custom-checkbox mb-4">
                                <input type="checkbox" id="customCheck1" required class="custom-control-input" 
                                formControlName="acceptTerms">
                                <label for="customCheck1" class="custom-control-label" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"> 
                                    I have read and agree to the DentIndia Terms and Conditions
                                </label>
                            </div>
                            <button [disabled]="loading" class="btn btn-primary login-btn mb-4">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register Clinic
                            </button>

                            <p class="login-card-footer-text">Already having dentindia account? <a
                                    href="account/login">Login here</a></p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>