<app-header></app-header>
<main class="d-flex py-3 py-md-0">
    <div class="container">
        <div class="card login-card">
            <div class="row no-gutters">
                <div class="col-md-6 col-sm-10 col-xs-12 auto">
                    <div class="card-body login">
                        <p class="login-card-description">Sign in</p>
                        <form action="#!" class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="email" class="">Email</label>
                                <input type="text" formControlName="username" name="username" id="username" class="form-control" placeholder="Jone@company.com" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password" class="">Password</label>
                                <input type="password" formControlName="password" name="password" id="password" class="form-control" placeholder="***********" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>

                            <a href="account/forgot-password" class="forgot-password-link">Forgot password?</a>

                            <button [disabled]="loading" class="btn btn-primary login-btn mb-4">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Login Now
                            </button>
                        </form>

                        <p class="login-card-footer-text">Don't have an account? <a href="account/register" class="text-reset">Register here</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>