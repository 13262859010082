import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LoginUser } from '../model/login-user.model';
import { AlertService } from '../service/alert.service';

@Injectable({ providedIn: 'root' })

export class AccountService {
    private userSubject: BehaviorSubject<LoginUser>;
    public user: Observable<LoginUser>;
    loggedInUser: LoginUser;

    constructor(private router: Router, private http: HttpClient, private alertService: AlertService) {
      const userJson = localStorage.getItem('user');
      this.loggedInUser = userJson !== null ? JSON.parse(userJson) : new LoginUser();
        this.userSubject = new BehaviorSubject<LoginUser>(this.loggedInUser);
        this.user = this.userSubject.asObservable();
    }

    public get loggedInUserValue(): LoginUser {
        return this.userSubject.value;
    }

    login(username: any, password: any) {
        let data = { username : username, password: password};
        (data)
        return this.http.post<LoginUser>(`${environment.loginUrl}`, data)
            .pipe(map(user => {
                (user);
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('info');
        localStorage.removeItem('patient');
        this.userSubject.next(new LoginUser());
        this.router.navigate(['/account/login']);
    }

    register(user: LoginUser) {
        return this.http.post(`${environment.registerUrl}`, user);
    }

    retriveState() {
        return this.http.get(`${environment.stateUrl}`);
    }

    getPatientInfo() {
        let patient: any = localStorage.getItem('patient');
        if (patient !== null)
            return JSON.parse(patient);
        else return patient;
    }
    
    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            if(error.status === 401){
                errorMessage = `Error Code: ${error.status} - Login Token Expired`;
            }else{
                errorMessage = `Error Code: ${error.status}`;
            }
        }
        // this.alertService.error(errorMessage);
        return throwError(errorMessage);
    }
}