import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-register-clinic',
  templateUrl: './register-clinic.component.html',
  styleUrls: ['./register-clinic.component.css']
})
export class RegisterClinicComponent implements OnInit {

  public stateData:any;
  public districtData:any;
  // rowData: Observable<any[]>;
  columnDefs = [
      { field: 'make', sortable: true, filter: true },
      { field: 'model', sortable: true, filter: true },
      { field: 'price', sortable: true, filter: true }
  ];

  rowData = [
      { make: 'Toyota', model: 'Celica', price: 35000 },
      { make: 'Ford', model: 'Mondeo', price: 32000 },
      { make: 'Porsche', model: 'Boxter', price: 72000 }
  ];
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }
}
