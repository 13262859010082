import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { AccountService, AlertService, SpinnerService, ConstantService } from 'src/app/service';

@Component({
  selector: 'app-search-large',
  templateUrl: './search-large.component.html',
  styleUrls: ['./search-large.component.css']
})
export class SearchLargeComponent implements OnInit {
  submitted = false; loading = false;
  searchForm!: FormGroup;
  public locationData!: Array<Select2OptionData>;
  public sourceData!: Array<Select2OptionData>;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService, private spinner: SpinnerService, public constant: ConstantService
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      search_text: ['', Validators.required],
      location: [''],
      category: [''],
    });
    this.locationData = [];
    this.sourceData = this.constant.category;
  }
  get f() { return this.searchForm.controls; }
  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }
  }

}
